import React from "react";
import { StaticImage } from "gatsby-plugin-image"
//import { Link } from "gatsby";
import Layout from "../../../../components/layout";
import SEO from "../../../../components/seo";
import * as CONST from "../../../../components/const";
const bg = "../../../../images/isms/ISMS_org.png";
const bg_b = "../../../../images/inquiry/Inquiry_org.png";
const kv = "../../../../images/employment/young/kv.jpg";

function IndexPage() {

  const STATIC_IMAGE_PLACEHOLDER = "blurred"; // blurred tracedSVG none
  const H5_CLASS = CONST.H5_CLASS + " mb-5 xl:mb-0 xl:h-16";

  return (
    <Layout addClassName="bg-gray-light">
      <SEO
        description="ALPHA WAVEの教育事業の詳細です。カリキュラムの詳細をご覧ください。"
        title="Curriculum"
      />
      <section className={CONST.SECTION_AREA_CLASS}>
        <div className={CONST.KEY_VISUAL_TITLE_MESSAGE_AREA_CLASS}>
          <div className={CONST.KEY_VISUAL_TITLE_AREA_CLASS}>
            <p className={CONST.KEY_VISUAL_TITLE_EN_CLASS}>Business content <span className={CONST.KEY_VISUAL_TITLE_JP_CLASS}>事業内容</span></p>
          </div>
          <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_HIDDEN_CLASS + " w-full"} src={kv} />
          <div className={CONST.KEY_VISUAL_MESSAGE_AREA_CLASS}>
            <div>
              <h1 className={CONST.H1_CLASS}>教育事業</h1>
              <p>ソフトウェアの開発に係わる教育事業を行っています。</p>
            </div>
          </div>
        </div>
        <div className={CONST.KEY_VISUAL_RIGHT_HALF_IMG_AREA_CLASS}>
          <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_SHOW_CLASS + " w-full"} src={kv} />
          <div className={CONST.KEY_VISUAL_RIGHT_HALF_MESSAGE_AREA_CLASS + " relative"}>
            <h2 className={CONST.H2_CLASS + " z-20"}><span className="inline-block">カリキュラム</span><span className="inline-block">詳細</span></h2>
            <StaticImage quality="100" alt="" layout="fullWidth" placeholder={STATIC_IMAGE_PLACEHOLDER} className="absolute w-full z-10 top-0 right-0" src={bg} />
          </div>
        </div>
      </section>

      <section className={CONST.SECTION_AREA_CLASS + " 3xl:-mt-24"}>
        <div className={CONST.CONTENTS_HALF_AREA_CLASS}>
          <div>
            <h5 className={H5_CLASS}>
              <span className="inline-block">ＩＴ基礎、</span>
              <span className="inline-block">アルゴリズムと</span>
              <span className="inline-block">プログラミング基礎</span>
            </h5>
            <div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>概要</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>フロー</div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>期間</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>24日間</div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>受講料</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>¥288,000</div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>対象者</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>
                  <p>プログラム未経験者</p>
                  <p>開発実務経験のない方</p>
                </div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>目標</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>
                  <p>システム開発の基礎を理解</p>
                  <p>ロジックとモジュール構成を理解</p>
                </div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>学習内容</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>
                  <p>変数と変数名の付け方</p>
                  <p>データ型</p>
                  <p>条件式</p>
                  <p>ループ</p>
                  <p>配列</p>
                  <p>メソッド</p>
                  <p>APIの利用</p>
                  <p>テスト</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={CONST.CONTENTS_HALF_AREA_CLASS}>
          <div>
            <h5 className={H5_CLASS}>
              <span className="inline-block">オブジェクト指向</span>
              <span className="inline-block">プログラミング</span>
            </h5>
            <div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>概要</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>Java</div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>期間</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>22日間</div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>受講料</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>¥264,000</div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>対象者</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>
                  <p>プログラミング基礎受講者</p>
                  <p>同等以上の知識のある方</p>
                </div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>目標</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>
                  <p>オブジェクト指向の理解</p>
                  <p>デザインパターンの理解</p>
                </div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>学習内容</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>
                  <p>クラスとオブジェクト</p>
                  <p>クラスの継承</p>
                  <p>インターフェイスの実装</p>
                  <p>クラス図/シーケンス図</p>
                  <p>例外</p>
                  <p>デザインパターン</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={CONST.SECTION_AREA_CLASS + " bg-white"}>
        <div className={CONST.CONTENTS_HALF_AREA_CLASS}>
          <div>
            <h5 className={H5_CLASS}>
              <span className="inline-block">C言語</span>
              <span className="inline-block">プログラミング</span>
            </h5>
            <div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>概要</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>ポインタ</div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>期間</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>7日間</div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>受講料</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>¥84,000</div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>対象者</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>
                  <p>プログラミング基礎受講者</p>
                  <p>同等以上の知識のある方</p>
                </div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>目標</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>
                  <p>チェーン構造を理解</p>
                  <p>無駄のないプログラム作成の考え方を理解</p>
                </div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>学習内容</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>
                  <p>ポインタ</p>
                  <p>関数</p>
                  <p>動的メモリ確保</p>
                  <p>チェーン構造</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={CONST.CONTENTS_HALF_AREA_CLASS}>
          <div>
            <h5 className={H5_CLASS}><span className="inline-block">データベース</span><span className="inline-block">基礎</span></h5>
            <div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>概要</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>DB基礎</div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>期間</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>5日間</div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>受講料</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>¥60,000</div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>対象者</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>
                  <p>プログラミング基礎受講者</p>
                  <p>同等以上の知識のある方</p>
                </div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>目標</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>
                  <p>設計されたテーブルを効率よく操作</p>
                  <p>データベースの基本を理解</p>
                </div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>学習内容</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>
                  <p>テーブルの理解と作成</p>
                  <p>SELECT/INSERT/UPDATE/DELETE</p>
                  <p>結合</p>
                  <p>サブクエリ</p>
                  <p>トランザクション</p>
                  <p>インデックス</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={CONST.SECTION_AREA_CLASS}>
        <div className={CONST.CONTENTS_HALF_AREA_CLASS}>
          <div>
            <h5 className={H5_CLASS}><span className="inline-block">Webプログラミング</span><span className="inline-block">基礎</span></h5>
            <div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>概要</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>Web基礎</div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>期間</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>20日間</div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>受講料</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>¥240,000</div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>対象者</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>
                  <p>オブジェクト指向プログラミング受講者</p>
                  <p>同等以上の知識のある方</p>
                </div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>目標</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>
                  <p>Webアプリの仕組みを理解</p>
                  <p>サーブレットプログラミングを理解</p>
                </div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>学習内容</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>
                  <p>Webアプリの仕組み</p>
                  <p>サーブレット/JSPとURLマッピング</p>
                  <p>セッション管理</p>
                  <p>データベースの利用</p>
                  <p>統合開発環境（Eclipse）の利用</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={CONST.CONTENTS_HALF_AREA_CLASS}>
          <div>
            <h5 className={H5_CLASS}><span className="inline-block">Webプログラミング</span><span className="inline-block">応用</span></h5>
            <div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>概要</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>Web応用</div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>期間</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>20日間</div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>受講料</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>¥240,000</div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>対象者</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>
                  <p>Webプログラミング基礎受講者</p>
                  <p>同等以上の知識のある方</p>
                </div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>目標</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>
                  <p>MVCモデルのWebアプリケーションを作成</p>
                  <p>UIを意識した画面を作成</p>
                </div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>学習内容</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>
                  <p>MVCモデル</p>
                  <p>フレームワーク</p>
                  <p>JavaScript</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={CONST.SECTION_AREA_CLASS + " relative bg-white"} >
        <StaticImage quality="100" alt="" layout="fullWidth" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.INQUIRY_BACK_GROUND_PATTERN_CLASS} src={bg_b} />
        <div className={CONST.CONTENTS_HALF_AREA_CLASS}>
          <div>
            <h5 className={H5_CLASS}><span className="inline-block">Androidアプリ</span><span className="inline-block">開発入門</span></h5>
            <div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>概要</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>Web応用</div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>期間</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>20日間</div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>受講料</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>¥240,000</div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>対象者</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>
                  <p>オブジェクト指向プログラミング受講者</p>
                  <p>同等以上の知識のある方</p>
                </div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>目標</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>Androidアプリ開発の基礎的な技術を理解</div>
              </div>
              <div className={CONST.TABLE_ROW_CLASS}>
                <div className={CONST.TABLE_TITLE_CLASS}>学習内容</div>
                <div className={CONST.TABLE_TEXT_CLASS + " xl:pl-20"}>
                  <p>アクティビティ</p>
                  <p>UI</p>
                  <p>SQLite</p>
                  <p>HTTP通信</p>
                  <p>デバッグ</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;
